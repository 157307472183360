// src/contexts/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();

  // Recuperar idioma desde localStorage o usar 'es' como predeterminado
  const storedLanguage = localStorage.getItem('language') || 'es';
  const [language, setLanguage] = useState(storedLanguage);

  // Aplicar el idioma al cargar la aplicación
  useEffect(() => {
    console.log("Idioma guardado en localStorage:", localStorage.getItem('language'));
    console.log("Idioma en i18n:", i18n.language);

    if (i18n.language !== storedLanguage) {
      console.log("Cambiando idioma a:", storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [storedLanguage, i18n]);

  const changeLanguage = (lng) => {
    if (lng !== language) {
      console.log("Cambiando idioma a:", lng);
      i18n.changeLanguage(lng);
      setLanguage(lng);
      localStorage.setItem('language', lng); // Guardar en localStorage
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
