// src/i18n/index.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../locales/en/translation.json';
import esTranslation from '../locales/es/translation.json';

// La configuración básica
i18n
  .use(initReactI18next) // Pasar i18n a react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    lng: 'es', // Idioma por defecto
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false, // React ya se encarga de la seguridad
    },
  });

export default i18n;
