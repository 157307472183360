// src/pages/Widget.jsx
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoRoundedIcon from '@mui/icons-material/InfoOutlined';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import ImplementacionGuiaImg from '../../assets/card-services.png'; // Asegúrate de que la ruta es correcta
import GeometryImage from '../../assets/left-image-widget.png'; // Asegúrate de que la ruta es correcta
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AWSContext } from '../../contexts/AWSContext';

const Widget = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);
  
  // Estado para el Modal de Contratar Widget
  const [modalOpen, setModalOpen] = useState(false);
  const [widgetUrl, setWidgetUrl] = useState('');
  const [originalBillingDay, setOriginalBillingDay] = useState(1)
  const [showWarning, setShowWarning] = useState(false);
  const [formError, setFormError] = useState('');
  const [billingDay, setBillingDay] = useState(1);
  const [formLoading, setFormLoading] = useState(false);
  const { isAWS } = useContext(AWSContext);
  const [assignDomainModalOpen, setAssignDomainModalOpen] = useState(false);
  const [selectedWidgetForDomain, setSelectedWidgetForDomain] = useState(null);
  const [domainToAssign, setDomainToAssign] = useState('');
  const [assignDomainError, setAssignDomainError] = useState('');

  const { t } = useTranslation(); // Extraemos solo 't'

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await api.get(`/customer-details?user_id=${user.id}`);
        console.log(response.data.billing_cycle); // Usa response.data directamente
        setBillingDay(Number(response.data.billing_cycle) || 1); // Asigna el día de facturación
        setOriginalBillingDay(Number(response.data.billing_cycle) || 1)
      } catch (err) {
        console.error('Error al obtener detalles del cliente:', err);
        setError(t('widget.errorCustomerDetails') || 'No se pudieron obtener los detalles del cliente.');
      } finally {
        // No necesitas hacer nada aquí
      }
    };
  
    if (user && user.id) {
      fetchCustomerDetails();
    }
  }, [user, t]);

  useEffect(() => {
    const fetchWidgets = async () => {
      try {
        const response = await api.get(`/servicios?user_id=${user.id}`);
        console.log('Widgets obtenidos:', response.data); // Log para depuración
        setWidgets(response.data);
      } catch (error) {
        console.error('Error al obtener widgets:', error);
        setError(t('widget.errorFetchingWidgets') || 'Error al obtener widgets.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchWidgets();
    }
  }, [user, t]);

  const handleModify = (widget) => {
    console.log('Navegando a widget', widget);
    
    const targetPath = isAWS ? '/dashboard/modify-plan-aws' : '/dashboard/modify-plan';

    navigate(targetPath, { 
        state: { 
            service_id: widget.id, 
            billing_interval_type: widget.billing_cycle_type 
        } 
    });
};


  const handleCancel = (widget) => {
    if (isAWS) {
        // Redirigir al AWS Marketplace para cancelar la suscripción
        const awsMarketplaceUrl = process.env.AWS_MARKETPLACE_CANCEL_URL || 'https://aws.amazon.com/marketplace/pp/prodview-3yziwygxgj6do';
        window.open(awsMarketplaceUrl, '_blank');
    } else {
        setSelectedWidget(widget);
        setCancelDialogOpen(true);
    }
  };

  const confirmCancel = async () => {
    console.log('Widget seleccionado para cancelar:', selectedWidget); // Log para depuración
    if (!selectedWidget || !selectedWidget.id) {
      setError(t('widget.errorCancelSubscription') || 'No se pudo obtener el subscription_id del widget seleccionado.');
      return;
    }
    try {
      await api.delete('/cancel-subscription', {
        data: { service_id: selectedWidget.id },
      });
      setCancelDialogOpen(false);
      // Actualizar la lista de widgets o recargar
      window.location.reload();
    } catch (error) {
      console.error('Error al cancelar suscripción:', error);
      setError(t('widget.errorCancelSubscription') || 'Error al cancelar suscripción.');
    }
  };

  // Funciones para manejar el Modal de Contratar Widget
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // Resetear los campos del formulario
    setWidgetUrl('');
    setBillingDay(originalBillingDay);
    setShowWarning(false);
    setFormError('');
  };

  const handleFormSubmit = async () => {
    setFormError('');
    // Validaciones
    try {
      setFormLoading(true);
      let domain = widgetUrl.trim();

      if (/^https?:\/\//i.test(domain)) {
        const url = new URL(domain);
        domain = url.hostname;
      }

      const domainPattern = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      if (!domainPattern.test(domain)) {
        throw new Error(t('widget.domainFormatError') || 'Formato de dominio inválido.');
      }

      const response = await api.post('/check-domain', { widgetUrl: domain });

      if (response.data.available) {
        // Navegar a la siguiente pantalla dentro del modal
        navigate('/dashboard/pricing', { state: { widgetUrl: domain, billingDay: Number(billingDay) } });
        handleCloseModal();
      } else {
        throw new Error(t('widget.domainInUseError') || 'El dominio ya está en uso.');
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setFormError(err.response.data.error);
      } else if (err.message === 'Formato de dominio inválido.') {
        setFormError(t('widget.domainFormatError') || 'Por favor, ingresa un dominio válido (e.g., www.ejemplo.com o ejemplo.com).');
      } else {
        setFormError(t('widget.urlFormatError') || 'Por favor, ingresa una URL válida (e.g., https://www.ejemplo.com o www.ejemplo.com).');
      }
    } finally {
      setFormLoading(false);
    }
  };

  const handleOpenAssignDomainModal = (widget) => {
    setSelectedWidgetForDomain(widget);
    setAssignDomainModalOpen(true);
  };
  
  const handleCloseAssignDomainModal = () => {
    setAssignDomainModalOpen(false);
    setDomainToAssign('');
    setAssignDomainError('');
    setSelectedWidgetForDomain(null);
  };
  
  const handleAssignDomainSubmit = async () => {
    setAssignDomainError('');
    try {
      // Realiza la llamada a tu servidor para asignar el dominio al widget (o servicio)
      const response = await api.post('/assign-domain', {
        service_id: selectedWidgetForDomain.id,
        domain: domainToAssign.trim(),
      });
      // Si la asignación fue exitosa, recarga la lista o actualiza el estado
      console.log(response)
      window.location.reload();
    } catch (error) {
      console.error('Error al asignar dominio:', error);
      setAssignDomainError(t('widget.assignDomainError') || 'Error al asignar dominio.');
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      {/* Título Principal */}
      <Typography variant="h4" gutterBottom sx={{ fontSize: '34px', fontWeight: 'medium', color: '#000' }}>
        {t('widget.title')}
      </Typography>

      {/* Mensaje Informativo */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 10,
          backgroundColor: '#E6F0FF',
          borderRadius: 2,
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        <InfoRoundedIcon sx={{ color: '#1976d2', mr: 1, ml: 1 }} />
        <Typography variant="body1" sx={{ color: '#0F52BD' }}>
          {t('widget.infoMessage')}
        </Typography>
      </Box>

      {/* Sección "Guía de Implementación" */}
      <Box sx={{ mb: 6 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: '34px', fontWeight: 'medium', color: '#000' }}
        >
          {t('widget.implementationGuideTitle')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
            flexWrap: 'wrap', // Permite que los elementos se ajusten en pantallas más pequeñas
          }}
        >
          {/* Imagen */}
          <Box
            component="img"
            src={ImplementacionGuiaImg}
            alt={t('widget.implementationGuideAlt') || 'Guía de Implementación'}
            sx={{
              width: { xs: '100%', sm: '376px' }, // Responsivo
              height: 'auto',
              borderRadius: 2,
              boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
              flexShrink: 0,
            }}
          />

          {/* Descripción y Botón */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 11,
              ml: 2,
              width: { xs: '100%', sm: '50%' }, // Responsivo
              maxWidth: { sm: '600px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderLeft: '1px solid #0F52BD',
                pl: 2,
              }}
            >
              <Typography variant="body1" gutterBottom sx={{ color: '#595959' }}>
                {t('widget.implementationGuideDescription')}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.open('/guía-implementación.pdf', '_blank');
              }}
              sx={{
                alignSelf: 'flex-start',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingRight: '37px',
                paddingLeft: '37px',
                textTransform: 'none',
                fontSize: '18px',
                borderRadius: '7px',
              }}
            >
              {t('widget.openButton')}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Sección "Dominios contratados" */}
      <Box sx={{ mt: 10, mb: 4, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ fontSize: '34px', fontWeight: 'medium', color: '#000' }}>
          {t('widget.contractedDomainsTitle')}
        </Typography>
        {!isAWS && ( // Solo muestra el botón si el cliente no es de AWS
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal} // Abrir el modal en lugar de navegar
            sx={{
              alignSelf: 'flex-start',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingRight: '30px',
              paddingLeft: '30px',
              textTransform: 'none',
              fontSize: '18px',
              borderRadius: '7px',
              marginLeft: '39px',
            }}
          >
            {t('widget.addButton')}
          </Button>
        )}
      </Box>

      {/* Listado de Widgets Contratados */}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      {loading ? (
        <Typography>{t('widget.loadingWidgets')}</Typography>
      ) : widgets.length > 0 ? (
        <Stack spacing={2} divider={<Box sx={{ width: '100%', height: '1px', bgcolor: '#0F52BD' }} />}>
          {widgets.map((widget) => (
            <Box
              key={widget.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 2,
                flexWrap: 'wrap', // Responsivo
              }}
            >
              {/* Imagen a la Izquierda */}
              <Box
                component="img"
                src={GeometryImage}
                alt={widget.nombre}
                sx={{
                  width: { xs: '50px', sm: '70px' }, // Responsivo
                  height: { xs: '50px', sm: '70px' },
                  borderRadius: '8px',
                  mr: 2,
                }}
              />

              {/* Detalles del Widget */}
              <Box sx={{ flex: 1, minWidth: '200px' }}>
                <Typography variant="h6">{widget.nombre}</Typography>
                <Typography variant="body2">
                  {t('widget.contractedDomain')}:{" "}
                  {widget.dominio ? (
                    widget.dominio
                  ) : (
                    isAWS && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenAssignDomainModal(widget)}
                        sx={{ textTransform: 'none', fontSize: '12px' }}
                      >
                        {t('widget.assignDomainButton')}
                      </Button>
                    )
                  )}
                </Typography>
                <Typography variant="body2">
                  {t('widget.contractDate')}: {new Date(widget.fecha_contratacion).toLocaleDateString()}
                </Typography>
                {!isAWS && (
                  <Typography variant="body2">
                    {t('widget.billingDay')}: {widget.billing_cycle_type}
                  </Typography>
                )}
              </Box>

              {/* Botones */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {/* Botón de Estadísticas */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(`/dashboard/${widget.dominio}/estadisticas`)}
                  sx={{
                    textTransform: 'none',
                    borderColor: '#0F52BD',
                    borderRadius: '10px',
                    paddingLeft: '37px',
                    paddingRight: '37px',
                    marginRight: '17px',
                    color: '#0F52BD',
                    '&:hover': {
                      backgroundColor: '#0F52BD',
                      color: '#fff',
                    },
                  }}
                >
                  {t('widget.statisticsButton')}
                </Button>

                {/* Botón de Editar */}
                <IconButton
                  onClick={() => handleModify(widget)}
                  sx={{
                    color: '#999',
                    '&:hover': {
                      color: '#0F52BD',
                    },
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>

                {/* Botón de Eliminar */}
                <IconButton
                  onClick={() => handleCancel(widget)}
                  sx={{
                    color: '#999',
                    '&:hover': {
                      color: '#FF0000',
                    },
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Stack>
      ) : (
        <Typography>{t('widget.noWidgets')}</Typography>
      )}

      {/* Dialog para cancelar suscripción */}
      <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)}>
        <DialogTitle>{t('widget.cancelSubscriptionTitle')}</DialogTitle>
        <DialogContent>
          <Typography>{t('widget.cancelSubscriptionMessage')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>{t('widget.cancelButton')}</Button>
          <Button onClick={confirmCancel} variant="contained" color="secondary">
            {t('widget.confirmCancelButton')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Contratar Widget */}
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle sx={{ fontWeight: 'medium', fontSize: '24px' }}>{t('widget.subscribeWidget')}</DialogTitle>
        <DialogContent>
          {formError && <Alert severity="error" sx={{ mb: 1 }}>{formError}</Alert>}
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ mb: 1, fontWeight: 'medium', fontSize: '14px' }}>{t('widget.domainInputLabel')}</Typography>
            <TextField
              fullWidth
              label={t('widget.domainInputPlaceholder')}
              value={widgetUrl}
              onChange={(e) => setWidgetUrl(e.target.value)}
              required
              sx={{ backgroundColor: '#F1F1F1' }}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Typography sx={{ mb: 1, fontWeight: 'medium', fontSize: '14px' }}>{t('widget.billingDayLabel')}</Typography>
              <Select
                labelId="billing-day-label"
                id="billing-day-select"
                name="billingDay"
                value={billingDay}
                sx={{ backgroundColor: '#F1F1F1' }}
                onChange={(e) => {
                  const selectedDay = parseInt(e.target.value, 10);
                  setBillingDay(selectedDay);
                  setShowWarning(selectedDay !== originalBillingDay);
                }}
              >
                <MenuItem value={1}>{t('widget.billingDayOptions.day1')}</MenuItem>
                <MenuItem value={8}>{t('widget.billingDayOptions.day8')}</MenuItem>
                <MenuItem value={15}>{t('widget.billingDayOptions.day15')}</MenuItem>
                <MenuItem value={22}>{t('widget.billingDayOptions.day22')}</MenuItem>
              </Select>
            </FormControl>

            {showWarning && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                {t('widget.billingDayWarning')}
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '25px', paddingBottom: '25px' }}>
          <Button onClick={handleCloseModal} disabled={formLoading} sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 'regular' }}>
            {t('widget.cancelButton')}
          </Button>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            disabled={formLoading}
            sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 'regular' }}
          >
            {formLoading ? <CircularProgress size={24} /> : t('widget.nextButton')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={assignDomainModalOpen} onClose={handleCloseAssignDomainModal} fullWidth maxWidth="sm">
        <DialogTitle sx={{ fontWeight: 'medium', fontSize: '24px' }}>
          {t('widget.assignDomainModalTitle')}
        </DialogTitle>
        <DialogContent>
          {assignDomainError && <Alert severity="error" sx={{ mb: 2 }}>{assignDomainError}</Alert>}
          <TextField
            fullWidth
            label={t('widget.domainInputLabel')}
            placeholder={t('widget.domainInputPlaceholder')}
            value={domainToAssign}
            onChange={(e) => setDomainToAssign(e.target.value)}
            sx={{ backgroundColor: '#F1F1F1' }}
            margin="dense"
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: '25px', paddingBottom: '25px' }}>
          <Button onClick={handleCloseAssignDomainModal} sx={{ textTransform: 'none', fontSize: '16px' }}>
            {t('widget.cancelButton')}
          </Button>
          <Button
            onClick={handleAssignDomainSubmit}
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none', fontSize: '16px' }}
          >
            {t('widget.assignDomainButton')}
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default Widget;
