// src/pages/Dashboard/WidgetStatistics.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Tooltip
} from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'; 
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Legend, LabelList, CartesianGrid, Cell } from 'recharts';
import iconoViewser from '../../assets/icono-viewser.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';

const WidgetStatistics = () => {
  const { domain } = useParams();
  const { t } = useTranslation(); // Extraer 't' para traducciones

  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)

  const fetchMetrics = async () => {
    const now = Date.now();
    const lastFetch = localStorage.getItem(`metrics-timestamp-${domain}`);
    const cachedMetrics = localStorage.getItem(`metrics-${domain}`);

    if (lastFetch && now - lastFetch < 60 * 60 * 1000 && cachedMetrics) {
      setMetrics(JSON.parse(cachedMetrics));
      setLoading(false);
      return;
    }

    try {
      const response = await api.post('/get-metrics', { domain });

      setMetrics(response.data);
      localStorage.setItem(`metrics-${domain}`, JSON.stringify(response.data));
      localStorage.setItem(`metrics-timestamp-${domain}`, now);
    } catch (err) {
      console.error('Error fetching metrics:', err);
      if (cachedMetrics) {
        setMetrics(JSON.parse(cachedMetrics));
        setError(t('widgetStatistics.errorFetchingLatestMetrics'));
        console.error(error)
      } else {
        setError(t('widgetStatistics.errorFetchingMetrics'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>{t('widgetStatistics.loadingMetrics')}</Typography>
      </Container>
    );
  }

  // Procesar datos
  const accessProfile = metrics.find(([profile]) => profile === 'access_profile')?.[1] || 0;

  const otherProfiles = metrics.filter(([profile]) => profile !== 'access_profile');

  const mostUsedProfile = otherProfiles.reduce((max, current) => (current[1] > max[1] ? current : max), ['', 0]);

  const profileTranslations = {
    visual_disability_profile: t('widgetStatistics.visualDisability'),
    learning_profile: t('widgetStatistics.learning'),
    motor_skills_disorder_profile: t('widgetStatistics.motorSkillsDisorder'),
    blindness_profile: t('widgetStatistics.blindness'),
    epilepsy_profile: t('widgetStatistics.epilepsy'),
    achromatopsia_profile: t('widgetStatistics.achromatopsia'),
    deuteranopia_profile: t('widgetStatistics.deuteranopia'),
    tdah_profile: t('widgetStatistics.tdah'),
    tritanomaly_profile: t('widgetStatistics.tritanomaly'),
    deuteranomaly_profile: t('widgetStatistics.deuteranomaly'),
    tritanopia_profile: t('widgetStatistics.tritanopia'),
    elderly_profile: t('widgetStatistics.elderly'),
    dislexy_profile: t('widgetStatistics.dyslexia'),
    protanopia_profile: t('widgetStatistics.protanopia'),
  };

  const translateProfileName = (profile) => {
    const translations = {
      visual_disability: t('widgetStatistics.visualDisability'),
      learning: t('widgetStatistics.learning'),
      motor_skills_disorder_profile: t('widgetStatistics.motorSkillsDisorder'),
      blindness: t('widgetStatistics.blindness'),
      epilepsy: t('widgetStatistics.epilepsy'),
      achromatopsia: t('widgetStatistics.achromatopsia'),
      deuteranopia: t('widgetStatistics.deuteranopia'),
      tdah: t('widgetStatistics.tdah'),
      tritanomaly: t('widgetStatistics.tritanomaly'),
      deuteranomaly: t('widgetStatistics.deuteranomaly'),
      tritanopia: t('widgetStatistics.tritanopia'),
      elderly: t('widgetStatistics.elderly'),
      dyslexia: t('widgetStatistics.dyslexia'),
      protanopia: t('widgetStatistics.protanopia'),
    };

    return translations[profile] || profile;
  };

  // Procesar datos
  const chartData = metrics
    .filter(([profile]) => profile !== 'access_profile')
    .map(([profile, count]) => ({
      name: profileTranslations[profile] || translateProfileName(profile.replace('_profile', '').trim()),
      value: count,
      isMostUsed: profile === mostUsedProfile[0],
    }));

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value, index } = props;
    const entry = chartData[index];
    return (
      <text
        x={x + width - 10} // Ajusta la posición horizontal según sea necesario
        y={y + height / 2}
        fill={entry.isMostUsed ? '#FFFFFF' : '#0F52BD'} // Blanco para la más usada, azul para las demás
        fontWeight="medium"
        fontSize={16}
        textAnchor="end"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 7 }}>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb:5, fontWeight:'medium', fontSize:'34px' }}>
        {t('widgetStatistics.statisticsFor')}{' '}
        <Typography
            component="span"
            sx={{
            fontWeight: 'medium',
            color: '#0F52BD', // Cambia el color del dominio
            marginLeft: '8px', // Espaciado entre el texto y el dominio
            fontSize: 'inherit', // Asegura que el tamaño coincide con el título principal
            }}
        >
            {domain}
        </Typography>
      </Typography>

      <Grid container spacing={3} sx={{ alignItems: 'stretch' }}>
        {/* Card 1: Affected Users */}
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Card
              sx={{
              boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
              flex: 1,
              borderRadius: 3,
              position: 'relative',
              }}
          >
              <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                  <Box display="flex" alignItems="center">
                  <LanguageOutlinedIcon sx={{ mr: 1, color: '#595959' }} />
                  <Typography sx={{ fontSize: '16px', color: '#000' }}>{t('widgetStatistics.affectedUsers')}</Typography>
                  </Box>
                  {/* Info Icon */}
                  <Tooltip
                      title={
                          <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                          {t('widgetStatistics.affectedUsersInfo')}
                          </Typography>
                      }
                      arrow
                      placement="top"
                      componentsProps={{
                          tooltip: {
                          sx: {
                              backgroundColor: '#0F52BD', // Solid blue background
                              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Optional: shadow for the tooltip
                              padding: '10px', // Internal spacing
                              borderRadius: '5px', // Rounded borders
                          },
                          },
                          arrow: {
                          sx: {
                              color: '#0F52BD', // Blue background for the arrow
                          },
                          },
                      }}
                  >
                  <InfoOutlinedIcon sx={{ fontSize: '20px', color: '#595959', cursor: 'pointer' }} />
                  </Tooltip>
              </Box>

              {/* Total centered */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '120px', // Adjust height as needed
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '48px', // Adjust font size as needed
                    fontWeight: 'medium',
                    color: '#000',
                  }}
                >
                  {accessProfile}
                </Typography>
              </Box>
              </CardContent>
          </Card>
        </Grid>

        {/* Card 2: Most Used Accessibility Profile */}
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
            <Card sx={{ boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)', flex: 1, borderRadius: 3, position: 'relative' }}>
                <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Box display="flex" alignItems="center">
                    <AccessibilityIcon sx={{ mr: 1, color: '#595959' }} />
                    <Typography sx={{ fontSize: '16px', color: '#000' }}>{t('widgetStatistics.mostUsedAccessibilityProfile')}</Typography>
                    </Box>
                    {/* Info Icon */}
                    <Tooltip
                        title={
                            <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                            {t('widgetStatistics.mostUsedProfileInfo')}
                            </Typography>
                        }
                        arrow
                        placement="top"
                        componentsProps={{
                            tooltip: {
                            sx: {
                                backgroundColor: '#0F52BD', // Solid blue background
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Optional: shadow for the tooltip
                                padding: '10px', // Internal spacing
                                borderRadius: '5px', // Rounded borders
                            },
                            },
                            arrow: {
                            sx: {
                                color: '#0F52BD', // Blue background for the arrow
                            },
                            },
                        }}
                    >
                    <InfoOutlinedIcon sx={{ fontSize: '20px', color: '#595959', cursor: 'pointer' }} />
                    </Tooltip>
                </Box>

                {/* Inner Card with Profile */}
                <Box
                    sx={{
                    position: 'relative',
                    p: 2,
                    mt: 7,
                    mb: 2,
                    maxWidth: '60%',
                    mx: 'auto',
                    boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
                    borderRadius: 3,
                    minHeight: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    }}
                >
                    {/* Icon #1 */}
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-10px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#0F52BD',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        borderRadius: '20%',
                        boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
                    }}
                    >
                    #1
                    </Box>

                    <Typography variant="subtitle1" sx={{ fontSize: '18px', color: '#000', textAlign: 'center', fontWeight: 'medium' }}>
                    {mostUsedProfile[0]
                        ? translateProfileName(mostUsedProfile[0].replace('_profile', '').trim())
                        : t('widgetStatistics.notAvailable')}
                    </Typography>
                </Box>
                </CardContent>
            </Card>
        </Grid>

        {/* Card 3: Coming Soon */}
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Card sx={{boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)', flex:1, borderRadius:3}}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Box
                  component="img"
                  src={iconoViewser} // Asegúrate de colocar la ruta correcta de la imagen
                  alt="Coming Soon"
                  sx={{
                      width: '24px', // Ajusta el tamaño según sea necesario
                      height: '24px',
                      mr: 1, // Margen derecho
                  }}
                />
                <Typography sx={{fontSize:'16px', color:'#000'}}>{t('widgetStatistics.comingSoon')}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Section: Most Used Accessibility Profiles */}
      <Box mt={8}>
        <Typography variant="h5" sx={{fontWeight:'medium', fontSize:'34px'}}>
            {t('widgetStatistics.mostUsedAccessibilityProfiles')}
        </Typography>
        <Typography variant="body2" sx={{color:'#4F4F4F', fontWeight:'light', mb:4, fontSize:'16px'}}>
            {t('widgetStatistics.accessibilityProfilesDescription')}
        </Typography>
        {metrics.length === 0 ? (
            <Box sx={{ textAlign: 'center', mt: 5 }}>
              <Typography variant="h6" color="text.secondary">
                {t('widgetStatistics.noMetricsAvailable')}
              </Typography>
            </Box>
          ) : (
        <Card sx={{ p: 2, boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)' }}>
          <Box sx={{ width: '100%', mx: '0' }}> {/* Gráfica centrada */}
            <ResponsiveContainer width="100%" height={700}>
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              barCategoryGap={40}
            >
              <CartesianGrid 
                strokeDasharray="5 5" 
                horizontal={false} // Desactiva líneas horizontales
                vertical={true} // Activa líneas verticales desde el eje X
                strokeWidth={2}
                stroke="#D9D9D9"
              />
              {/* Eje X con líneas visibles */}
              <XAxis
                type="number"
                tick={{ fontSize: 14 }}
                axisLine={{ stroke: '#595959' }}
                tickLine={false} // Desactiva las pequeñas líneas de los ticks
                stroke="#595959"
              />
              {/* Eje Y con línea visible */}
              <YAxis
                dataKey="name"
                type="category"
                tickLine={false}
                width={160}
                axisLine={{ stroke: '#595959' }}
                tick={({ x, y, payload }) => {
                  const entry = chartData[payload.index];
                  return (
                    <text
                      x={x - 10}
                      y={y}
                      fill={entry.isMostUsed ? '#000' : '#595959'}
                      fontWeight={entry.isMostUsed ? 'medium' : 'normal'}
                      fontSize={16}
                      textAnchor="end"
                    >
                      {payload.value}
                    </text>
                  );
                }}
              />
              <Tooltip />
              <Legend
                formatter={(value) => (value === 'value' ? t('widgetStatistics.users') : value)} 
              />
              <Bar dataKey="value" barSize={30} radius={[0, 5, 5, 0]}>
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.isMostUsed ? '#0F52BD' : '#C7D7F0'}
                    stroke={entry.isMostUsed ? '#0A3E91' : '#7894CC'}
                    strokeWidth={2}
                  />
                ))}
                <LabelList content={renderCustomLabel} />
              </Bar>
            </BarChart>
            </ResponsiveContainer>
          </Box>
        </Card>
          )}
      </Box>
    </Container>
  );
};

export default WidgetStatistics;
