// src/App.jsx
import React from 'react';
import AppRoutes from './routes/AppRoutes';
import AuthProvider from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { AWSProvider } from './contexts/AWSContext';

const App = () => {
  return (
    <LanguageProvider>
      <AWSProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </AWSProvider>
    </LanguageProvider>
  );
};

export default App;
