// src/contexts/AWSContext.js
import React, { createContext, useState } from 'react';

export const AWSContext = createContext();

export const AWSProvider = ({ children }) => {
  const [isAWS, setIsAWS] = useState(false);

  return (
    <AWSContext.Provider value={{ isAWS, setIsAWS }}>
      {children}
    </AWSContext.Provider>
  );
};
