// src/pages/Register/Register.jsx
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Divider,
  Link as MuiLink,
  Checkbox,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google'; // Icono de Google
import logo from '../../assets/logo-viewser.png'; // Ruta de tu logo
import registerImage1 from '../../assets/register-image-1.webp'; // Imagen para el paso 1
import registerImage2 from '../../assets/register-image-2.webp'; // Imagen para el paso 2
import registerImage3 from '../../assets/register-image-3.webp'; // Imagen para el paso 3
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import { getData } from 'country-list';
import StepButton from '../../components/StepButton';
import { prismaClient } from '../../services/prismaClient';
import { getCountryCallingCode } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../contexts/LanguageContext';
import api from '../../services/api';

const steps = ['', '', '', ''];

const Register = () => {
  const { signUp, user, signInWithGoogle } = useAuth(); // Obtenemos el usuario global del contexto
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [formError, setFormError] = useState('');
  const [localUser, setLocalUser] = useState(null); // Evitamos confusión con el usuario global
  const { t } = useTranslation(); // Extract 't' and 'i18n' for translations
  const { changeLanguage, language } = useContext(LanguageContext);
  const countries = getData();

  const searchParams = new URLSearchParams(location.search);
  const awsToken = searchParams.get('token');

  // Imágenes para cada paso
  const stepImages = [registerImage1, registerImage2, registerImage3, registerImage3];

  // Esquemas de validación por paso
  const validationSchemas = [
    Yup.object().shape({
      customer_email: Yup.string()
        .email(t('validation.invalidEmail'))
        .required(t('validation.requiredEmail')),
      password: Yup.string()
        .min(8, t('validation.passwordLength'))
        .max(20, t('validation.passwordLength'))
        .matches(/[^a-zA-Z0-9]/, t('validation.passwordSpecialChar'))
        .required(t('validation.requiredPassword')),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], t('validation.passwordMismatch'))
        .required(t('validation.requiredConfirmPassword')),
      use_8_20_chars: Yup.boolean(),
      use_special_char: Yup.boolean(),
    }),
    Yup.object().shape({
      customer_type: Yup.string()
        .oneOf(['Business', 'Consumer'])
        .required(t('validation.requiredUserType')),
    }),
    Yup.object().shape({
      customer_name: Yup.string().required(t('validation.requiredName')),
      fiscal_identification: Yup.string().required(t('validation.requiredFiscalID')),
      address: Yup.string(),
      city: Yup.string(),
      region: Yup.string(),
      postal_code: Yup.string(),
      country_code: Yup.string()
        .length(2, t('validation.countryCodeLength')),
      date_of_birth: Yup.string().when('customer_type', {
        is: 'Consumer',
        then: (schema) => schema.required(t('validation.requiredBirthDate')),
        otherwise: (schema) => schema,
      }),
      customer_phone: Yup.string()
        .matches(/^\+?\d{7,15}$/, t('validation.invalidPhone'))
        .required(t('validation.requiredPhone')),
    }),
  ];

  const initialValues = {
    customer_email: user?.email || '',
    password: '',
    confirm_password: '',
    customer_type: '',
    customer_name: '',
    fiscal_identification: '',
    address: '',
    city: '',
    region: '',
    postal_code: '',
    country_code: '',
    date_of_birth: '', // Solo para Consumer
    use_8_20_chars: false,
    use_special_char: false,
    customer_phone: '',
    billing_cycle: '01',
  };

  useEffect(() => {
    if (user && !localUser) {
      console.log('Estableciendo localUser a user');
      setLocalUser(user);
    }
  }, [user, localUser]);

  const getPhonePrefix = (countryCode) => {
    try {
      return countryCode ? `+${getCountryCallingCode(countryCode)}` : '';
    } catch (error) {
      console.error('Error obteniendo el prefijo telefónico:', error);
      return '';
    }
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  useEffect(() => {
    if (location.state?.fromOAuth) {
      setActiveStep(1); // Comenzar en el segundo paso
    }
  }, [location]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setFormError('');
    try {
      if (activeStep === 0) {
        if (awsToken) {
          // Ejecutamos el signUp para registrar al usuario en Supabase
          const { user, error: authError } = await signUp({
            email: values.customer_email,
            password: values.password,
          });
          if (authError) {
            setFormError(authError.message);
            setSubmitting(false);
            return;
          }
          if (user) {
            setLocalUser(user);
            // Llamamos al endpoint /customers-api para crear la cuenta en el backend
            const response = await api.post('/customers-api', {
              user_id: user.id, // asegúrate de que user.id esté disponible
              customer_email: values.customer_email,
              aws_token: awsToken, // incluimos el token AWS
            });
            if (response.data.error) {
              setFormError(response.data.error);
              setSubmitting(false);
              return;
            }
            // Finalizado el registro, redirigimos al login
            navigate('/login');
          }
        } else {
          // Flujo normal: sin token AWS, se registra y se continúa a los siguientes pasos
          const { user, error: authError } = await signUp({
            email: values.customer_email,
            password: values.password,
          });
          if (authError) {
            setFormError(authError.message);
            setSubmitting(false);
            return;
          }
          if (user) {
            setLocalUser(user);
            handleNext();
          }
        }
      } else if (activeStep === 1) {
        handleNext();
      } else if (activeStep === 2) {
        handleNext();
      } else if (activeStep === 3) {
        const customerDetails = {
          fiscal_identification: values.fiscal_identification,
          billing_cycle: values.billing_cycle,
          ...(values.customer_type === 'Consumer' && {
            date_of_birth: values.date_of_birth,
          }),
          billing_address: {
            address: values.address || '',
            city: values.city || '',
            region: values.region || '',
            postal_code: values.postal_code || '',
            country_code: values.country_code || '',
          },
        };

        if (!localUser) { // Verifica si el usuario local está definido
          setFormError('Usuario no autenticado.');
          setSubmitting(false);
          return;
        }

        const endpoint = awsToken ? '/customers-aws' : '/customers';
        const response = await prismaClient.post(endpoint,{  
          user_id: localUser.id,
          customer_type: values.customer_type,
          customer_name: values.customer_name,
          customer_email: values.customer_email,
          customer_phone: values.customer_phone,
          customer_details: customerDetails,
        });

        if (response.data.error) {
          setFormError(response.data.error);
          setSubmitting(false);
          return;
        }
        navigate('/dashboard');
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setFormError(error.response.data.error || 'Este cliente ya está registrado en AWS.');
      } else {
        setFormError('Ocurrió un error al registrar el cliente.');
      }
    } finally {
      setSubmitting(false);
    }
  };
  const handleLanguageChange = (event) => {
    const lng = event.target.value;
    changeLanguage(lng);
  };

  // Redirige al Dashboard si el usuario está autenticado globalmente
  /* if (user) {
    navigate('/dashboard');
  } */

  return (
    <Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
      {/* Columna Izquierda - Formulario */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', // Distribuye el espacio entre arriba y abajo
          alignItems: 'center',
          paddingTop: { xs: 4, sm: 6 },    // Más padding en la parte superior
          paddingBottom: { xs: 2, sm: 3 }, // Menos padding en la parte inferior
          paddingLeft: { xs: 2, sm: 4 },
          paddingRight: { xs: 2, sm: 4 },
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1, // Permite que este bloque ocupe el espacio disponible
            width: '100%',
          }}
        >
          {/* Título y Subtexto */}

          {/* Stepper - Barra de Progreso */}
          <Stepper activeStep={activeStep} sx={{ width: '70%', mb: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ textAlign: 'center', mb: 4, paddingX: 2 }}>
            {activeStep === 0 && (
              <>
              <Typography
                variant="h4"
                sx={{
                  color: '#000000',
                  textShadow: '#7894CC 1px 0 19.5px;',
                  mb: 1,
                  fontWeight:'700'
                }}
              >
                Make{' '}
                <Box component="span" sx={{ color: '#0F52BD', textShadow: '#7894CC 1px 0 19.5px;', }}>
                  Accessibility
                </Box>{' '}
                happen
              </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#555555',
                  }}
                >
                  {t('register.subtitle1')}
                </Typography>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    textShadow: '#7894CC 1px 0 19.5px;',
                    mb: 1,
                    mt: 6
                  }}
                >
                  {t('register.createAccount')}
                </Typography>
              </>
            )}
                
                {activeStep === 2 && (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        color: '#000000',
                        textShadow: '#7894CC 1px 0 19.5px;',
                        mb: 1,
                      }}
                    >
                      <Box component="span" sx={{ color: '#0F52BD', textShadow: '#7894CC 1px 0 19.5px;' }}>
                        Viewser
                      </Box>{' '}
                      {t('register.businessTitle')}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: '#555555',
                        textAlign: 'center',
                        maxWidth: '500px', // Establece un ancho máximo
                        margin: '0 auto',
                      }}
                    >
                      {t('register.businessSubtitle', { email: localUser.email })}
                    </Typography>
                  </>
                )}
            {activeStep === 3 && (
              <>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    color: '#000000',
                    textShadow: '#7894CC 1px 0 19.5px;',
                    mb: 1,
                  }}
                >
                  {t('register.personalTitle')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#555555',
                    textAlign: 'center',
                    maxWidth: '500px', // Establece un ancho máximo
                    margin: '0 auto',
                  }}
                >
                  {t('register.personalSubtitle', { email: localUser.email })}
                </Typography>
              </>
            )}
          </Box>

          {/* Contenedor del Formulario con Sombra */}
          <Box
            sx={{
              width: '100%',
              maxWidth: '400px',
              backgroundColor: '#FFFFFF',
              padding: { xs: 2, sm: 3 }, // Reducir padding para ajustar espacio
              borderRadius: '8px',
              boxShadow: '#a7bbe6 1px 0 50.5px', // Sombra similar al Login
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Botón de Registro con Google */}
            {!awsToken && activeStep !== 1 && activeStep !== 2 && activeStep !== 3 && (
              <>
                {/* Botón de Registro con Google */}
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<GoogleIcon />}
                  onClick={async () => {
                    const { error } = await signInWithGoogle();
                    if (error) {
                      setFormError(error.message);
                    }
                  }}
                  sx={{
                    borderRadius: '5px',
                    textTransform: 'none',
                    paddingY: 1.2,
                    mb: 2,
                    borderColor: '#0F52BD',
                    color: '#0F52BD',
                    '&:hover': {
                      borderColor: '#0F52BD',
                      backgroundColor: '#f0f0f0',
                    },
                  }}
                >
                  {t('login.continueWithGoogle')}
                </Button>

                {/* Barra Separadora */}
                <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                  <Divider sx={{ flexGrow: 1 }} />
                  <Typography variant="body2" sx={{ mx: 2, color: '#888888' }}>
                    o
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Box>
              </>
            )}


            {/* Formulario de Registro */}
            <Formik
              initialValues={{
                ...initialValues,
                customer_email: localUser?.email || '',
              }}
              enableReinitialize
              validationSchema={validationSchemas[activeStep]}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                setFieldValue
              }) => (
                <Form>
                  {activeStep === 0 && (
                    <>
                      <TextField
                        fullWidth
                        label={t('register.email')}
                        name="customer_email"
                        type="email"
                        value={values.customer_email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.customer_email && Boolean(errors.customer_email)}
                        helperText={touched.customer_email && errors.customer_email}
                        margin="dense"
                        required
                      />
                      <TextField
                        fullWidth
                        label={t('register.password')}
                        name="password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        margin="dense"
                        required
                      />
                      <TextField
                        fullWidth
                        label={t('register.confirmPassword')}
                        name="confirm_password"
                        type="password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.confirm_password && Boolean(errors.confirm_password)}
                        helperText={touched.confirm_password && errors.confirm_password}
                        margin="dense"
                        required
                      />
                
                      {/* Checkboxes de Validación */}
                      <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.password.length >= 8 && values.password.length <= 20}
                              disabled
                              color="primary"
                            />
                          }
                          label={t('register.passwordRequirement1')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={/[!ºª|@·#$~%¬/()='?¡¿`^+[*{}¨Ç´\-_.:,;]/.test(values.password)} 
                              disabled
                              color="primary"
                            />
                          }
                          label={t('register.passwordRequirement2')}
                        />
                      </Box>
                    </>
                  )}

                  {activeStep === 1 && (
                    <>
                      {/* Botones Personalizados para "Empresa" y "Personal" */}
                      <StepButton
                        isSelected={values.customer_type === 'Business'}
                        label={t('register.business')}
                        subtext={t('register.businessSubtext')}
                        onClick={() => {
                          setFieldValue('customer_type', 'Business');
                          handleNext();
                        }}
                      />
                      <StepButton
                        isSelected={values.customer_type === 'Consumer'}
                        label={t('register.personal')}
                        subtext={t('register.personalSubtext')}
                        onClick={() => {
                          setFieldValue('customer_type', 'Consumer');
                          handleNext();
                        }}
                      />
                      {touched.customer_type && errors.customer_type && (
                        <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                          {t('register.customerTypeError')}
                        </Typography>
                      )}
                    </>
                  )}

                  {activeStep === 2 && (
                    <>
                      <Autocomplete
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('register.country')}
                            name="country_code"
                            error={touched.country_code && Boolean(errors.country_code)}
                            helperText={touched.country_code && errors.country_code}
                            margin="dense"
                            required
                          />
                        )}
                        onChange={(event, newValue) => {
                          setFieldValue('country_code', newValue ? newValue.code : '');
                        }}
                      />

                      <TextField
                        fullWidth
                        label={t('register.name')}
                        name="customer_name"
                        value={values.customer_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.customer_name && Boolean(errors.customer_name)}
                        helperText={touched.customer_name && errors.customer_name}
                        margin="dense"
                        required
                      />

                      <TextField
                        fullWidth
                        label={values.customer_type === 'Business' ? t('register.cif') : t('register.nif')}
                        name="fiscal_identification"
                        value={values.fiscal_identification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.fiscal_identification && Boolean(errors.fiscal_identification)}
                        helperText={touched.fiscal_identification && errors.fiscal_identification}
                        margin="dense"
                        required
                      />

                      <TextField
                        fullWidth
                        label={t('register.phone')}
                        name="customer_phone"
                        type="tel"
                        value={values.customer_phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.customer_phone && Boolean(errors.customer_phone)}
                        helperText={touched.customer_phone && errors.customer_phone}
                        margin="dense"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {getPhonePrefix(values.country_code)}
                            </InputAdornment>
                          ),
                        }}
                      />

                      {values.customer_type === 'Consumer' && (
                        <TextField
                          fullWidth
                          label={t('register.dateOfBirth')}
                          name="date_of_birth"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.date_of_birth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.date_of_birth && Boolean(errors.date_of_birth)}
                          helperText={touched.date_of_birth && errors.date_of_birth}
                          margin="dense"
                          required
                        />
                      )}
                    </>
                  )}
                  {activeStep === 3 && (
                    <>
                      <TextField
                        select
                        fullWidth
                        label={t('register.billingDay')}
                        name="billing_cycle"
                        value={values.billing_cycle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.billing_cycle && Boolean(errors.billing_cycle)}
                        helperText={touched.billing_cycle && errors.billing_cycle}
                        margin="dense"
                      >
                        <MenuItem value="01">01</MenuItem>
                        <MenuItem value="08">08</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="22">22</MenuItem>
                      </TextField>

                      <TextField
                        fullWidth
                        label={t('register.address')}
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        margin="dense"
                      />

                      <TextField
                        fullWidth
                        label={t('register.city')}
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                        margin="dense"
                      />

                      <TextField
                        fullWidth
                        label={t('register.region')}
                        name="region"
                        value={values.region}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.region && Boolean(errors.region)}
                        helperText={touched.region && errors.region}
                        margin="dense"
                      />

                      <TextField
                        fullWidth
                        label={t('register.postalCode')}
                        name="postal_code"
                        value={values.postal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.postal_code && Boolean(errors.postal_code)}
                        helperText={touched.postal_code && errors.postal_code}
                        margin="dense"
                      />
                    </>
                  )}

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      variant="contained"
                    >
                      {t('register.back')}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {activeStep === steps.length - 1 ? t('register.continue') : t('register.next')}
                    </Button>
                  </Box>

                  {formError && <Alert severity="error" sx={{ mt: 2 }}>{formError}</Alert>}
                </Form>
              )}
            </Formik>
          </Box>
          {activeStep === 0 && (
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Typography variant="body2" sx={{ color: '#555' }}>
              {t('register.haveAccount')}{' '}
              <MuiLink component={Link} to="/login" sx={{ color: '#0F52BD', fontWeight: 'medium', textDecoration: 'none' }}>
                {t('register.loginHere')}
              </MuiLink>
            </Typography>
          </Box>
          )}
        </Box>

        {/* Logo y Enlaces Inferiores */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            mt: 4, // Margen superior para separar del formulario
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo de Viewser"
            sx={{ width: '120px', mb: 1 }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl variant="standard" sx={{ minWidth: 80 }}>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                onChange={handleLanguageChange}
                label={t('login.language')}
                sx={{
                  color: '#74767a',
                  '& .MuiSvgIcon-root': {
                    color: '#74767a', 
                  },
                  fontSize: '14px',
                  minWidth: '80px',
                }}
              >
                <MenuItem value="es">Español</MenuItem>
                <MenuItem value="en">English</MenuItem>
              </Select>
            </FormControl>
            <MuiLink href="#" variant="body2" sx={{ color: '#74767a', textDecorationColor:'#74767a' }}>
              {t('login.help')}
            </MuiLink>

            {/* Privacy Policy Link */}
            <MuiLink href="#" variant="body2" sx={{ color: '#74767a', textDecorationColor:'#74767a' }}>
              {t('login.privacyPolicy')}
            </MuiLink>
          </Box>
        </Box>
      </Grid>

      {/* Columna Derecha - Imagen */}
      <Grid
        item
        xs={false}
        md={6}
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'relative',
          height: '100%', // Asegura que ocupe toda la altura del contenedor
        }}
      >
        <Box
          component="img"
          src={stepImages[activeStep]} // Cambia la imagen según el paso activo
          alt={`Imagen del paso ${activeStep + 1}`}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.8, // Ajusta la opacidad si es necesario
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Register;
