import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Grid,
  Alert
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';



const ModificarPlanAWS = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { service_id } = location.state || {}; // Obtener service_id desde la navegación
  const [currentPlan, setCurrentPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [billingPeriod, setBillingPeriod] = useState('month');
  const [currentBillingInterval, setCurrentBillingInterval] = useState(null);

  const { t } = useTranslation();

  // Carga de datos del servicio y planes
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener detalles del servicio actual
        const responseService = await api.get(`/service-details/${service_id}`);
        setCurrentPlan(responseService.data.current_plan);
        setCurrentBillingInterval(responseService.data.billing_interval_type);
        console.log('Detalles del plan:', responseService.data);
        // Obtener todos los planes disponibles
        const response = await api.get('/billing-plans');
        let fetchedPlans = response.data;
        // Ordenar los planes: Small, Medium, Large
        const order = ['Small', 'Medium', 'Large'];
        fetchedPlans.sort((a, b) => order.indexOf(a.plan_name) - order.indexOf(b.plan_name));
        setPlans(fetchedPlans);
      } catch (err) {
        console.error('Error al obtener datos:', err);
        setError('No se pudieron obtener los datos necesarios.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id && service_id) {
      fetchData();
    } else {
      setError('Faltan datos necesarios para modificar el plan.');
      setLoading(false);
    }
  }, [user, service_id]); // Se elimina currentPlan de las dependencias

  // Configurar precios seleccionados
  useEffect(() => {
    if (plans.length === 0) return;
    const initialSelectedPrices = {};
    plans.forEach(plan => {
      if (plan.prices && plan.prices.length > 0) {
        // Seleccionar el primer price_id por defecto que coincida con el billingPeriod
        const defaultPrice = plan.prices.find(p => p.recurring.interval === billingPeriod) || plan.prices[0];
        initialSelectedPrices[plan.billing_plan_id] = defaultPrice.id;
      }
    });
  }, [billingPeriod, plans]); // Se eliminó selectedPrices de dependencias

  // Cargar método de pago (solo se ejecuta cuando service_id cambia


  const planTiers = {
    Small: 1,
    Medium: 2,
    Large: 3,
  };
  
  const handleSelectPlan = (plan) => {
    if (!currentPlan || !currentBillingInterval) {
      setError('No se encontró el plan actual.');
      return;
    }
    
    // Convertir el billing_interval_type actual a 'month' o 'year'
    const currentBillingPeriod = currentBillingInterval === 'M' ? 'month' : 'year';
    // Comprobar que el período de facturación no se cambia en la modificación
    if (billingPeriod !== currentBillingPeriod) {
      setError('No se puede extender la duración del contrato desde el dashboard.');
      return;
    }
    
    const currentTier = planTiers[currentPlan.plan_name] || 0;
    const newTier = planTiers[plan.plan_name] || 0;
    
    // Bloquear si el nuevo plan es idéntico o un downgrade
    if (newTier <= currentTier) {
      setError('No se puede modificar a un plan idéntico o hacer downgrade.');
      return;
    }
    
    // Si las validaciones son correctas, redirigimos al cliente a la página del producto en AWS Marketplace
    const awsMarketplaceUrl = process.env.AWS_MARKETPLACE_MODIFY_URL || 'https://aws.amazon.com/marketplace/pp/prodview-3yziwygxgj6do';
    window.location.href = awsMarketplaceUrl;
  };
  
  

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>{t('widgetPricing.loadingBillingPlans')}</Typography>
      </Container>
    );
  }

  const extractVisitsCount = (desc) => {
    // Asumiendo que el formato siempre es "Hasta 100.000"
    const regex = /Hasta\s+([\d.]+)/i;
    const match = desc.match(regex);
    if (match && match[1]) {
      // Retornar el número sin puntos (ej. "100.000" -> "100000")
      return match[1].replace(/\./g, '');
    }
    return desc; // Retorna la descripción completa si no coincide
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <Container  sx={{ mt: 10, mb: 10 }}>
      {/* Título Principal */}
      <Typography gutterBottom sx={{ fontWeight: 'medium', color: '#000', fontSize:34 }}>
      {t('widgetPricing.modifyTitle')}: {currentPlan.plan_name}
      </Typography>

      {/* Textos Secundarios */}
      <Typography gutterBottom sx={{ color: '#595959', fontWeight:'medium', fontSize:'18px' }}>
      {t('widgetPricing.modifySubtitle')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start', // Alinea los textos al inicio
          alignItems: 'center',
          mt: 1, // Margen superior para separación
          gap: 0.5, // Espaciado entre los textos
          mb:4
        }}
      >
        {/* Texto de pregunta */}
        <Typography
          variant="body2"
          sx={{
            color: '#777',
            fontSize: '16px',
          }}
        >
          {t('widgetPricing.contactQuestion')}
        </Typography>

        {/* Enlace para contacto */}
        <Typography
          component="a"
          href="https://goviewser.com/contacto/"
          target="_blank" // Abre el enlace en una nueva pestaña
          rel="noopener noreferrer" // Seguridad al usar target="_blank"
          sx={{
            textDecoration: 'none',
            color: '#0F52BD',
            fontWeight: 'medium',
            fontSize: '16px',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline', // Subrayado al pasar el ratón
            },
          }}
        >
          {t('widgetPricing.contactLink')}
        </Typography>
      </Box>

      {/* Botón para alternar entre mensuales y anuales */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              borderRadius: '25px',
              
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                fontWeight: 'medium',
                marginRight: '8px',
                color: billingPeriod === 'month' ? '#0F52BD' : '#666',
              }}
            >
              {t('widgetPricing.monthly')}
            </Typography>
            <Box
              onClick={() => setBillingPeriod((prev) => (prev === 'month' ? 'year' : 'month'))}
              sx={{
                position: 'relative',
                width: '60px',
                height: '30px',
                borderRadius: '15px',
                backgroundColor: '#C7D7F0',
                borderColor:'#0F52BD',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: '#D0D0D0',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '3px',
                  left: billingPeriod === 'month' ? '3px' : 'calc(100% - 27px)',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: '#0F52BD',
                  transition: 'left 0.3s',
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                fontWeight: 'medium',
                marginLeft: '8px',
                color: billingPeriod === 'year' ? '#0F52BD' : '#666',
              }}
            >
              {t('widgetPricing.annual')}
            </Typography>
          </Box>
        </Box>


      {/* Listado de Planes */}
      <Grid container spacing={0}> {/* Cambiado spacing de 4 a 0 para eliminar espacios */}
        {/* Renderizar los tres planes de la API en orden Small, Medium, Large */}
        {plans.map((plan) => {
          // Encontrar el precio según el periodo seleccionado
          const price = plan.prices.find(p => p.recurring.interval === billingPeriod) || plan.prices[0];
          if (!price) {
            return null; // Si no hay precio para el periodo, omitir
          }

          // Determinar si el plan es 'Medium' para aplicar estilos especiales
          const isMedium = plan.plan_name.toLowerCase() === 'medium';

          return (
            <Grid item xs={12} sm={6} md={3} key={plan.billing_plan_id}>
              <Card
                sx={{
                  borderTop: '4px solid #0F52BD',
                  height: isMedium ? '362px' : '330px', // Altura de las tarjetas
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderRadius: '10px',
                  boxShadow: '0px 2px 25px 0px rgba(15, 82, 189, 0.46)',
                  zIndex: isMedium ? 10 : 1,
                  position: 'relative', // Necesario para usar transform
                  top: isMedium ? '-31px' : '0', // Elevar la tarjeta Medium
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  {/* Encabezado Azul */}
                  <Box
                    sx={{
                      backgroundColor: '#0F52BD', // Fondo azul
                      padding: '16px', // Espaciado interno
                      textAlign: 'left', // Centrar texto dentro del encabezado
                      width: '100%', // Asegurar que ocupe todo el ancho
                    }}
                  >
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }}>
                      {plan.plan_name}
                    </Typography>
                    {isMedium && (
                      <Box
                        sx={{
                          position: 'absolute',
                          left: '76%',
                          transform: 'translateX(-50%)', // Centrar horizontalmente
                          backgroundColor: '#fff', // Fondo amarillo
                          border:'1px solid #0F52BD',
                          color: '#0F52BD',
                          padding: '4px 12px',
                          fontSize: '16px',
                          fontWeight: 'regular',
                          borderRadius: '18px',
                          paddingX:2
                        }}
                      >
                        {t('widgetPricing.popular') || 'Popular'}
                      </Box>
                    )}
                  </Box>

                  {/* Precio en Grande */}
                  <Box
                    sx={{
                      padding: '16px',
                      mt: isMedium ? 4 : 0, // Aumentar espacio para plan Medium
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 0,
                        fontSize: '50px',
                        fontWeight: 'medium',
                        color: '#000',
                      }}
                    >
                      {`${formatPrice(price.unit_amount)}€`}
                      <Typography
                        component="span"
                        sx={{
                          fontSize: '16px', // Tamaño de letra diferente
                          fontWeight: '400', // Peso de fuente diferente
                          marginLeft: '4px', // Espaciado entre el precio y el texto
                          color: '#666', // Color diferente si lo deseas
                        }}
                      >
                        {`/${t(`widgetPricing.${billingPeriod === 'month' ? 'month' : 'year'}`)}`}
                      </Typography>
                    </Typography>

                    {/* Línea Divisoria Azul */}
                    <Box sx={{ borderBottom: '1px solid #7894CC', mb: 1 }} />
                    {/* Visitas */}
                    <Typography variant="subtitle1" sx={{ mb: 1, display: 'flex', flexDirection: 'column' }}>
                      {/* Reducir espacio */}
                      <span style={{ fontWeight: 'bold', color: '#595959', fontSize: '18px' }}>
                        {t('widgetPricing.upTo', { count: formatNumber(extractVisitsCount(plan.plan_desc)) })}
                      </span>
                      <span style={{ color: '#666', fontSize: '14px' }}>
                        {t('widgetPricing.visitsPerMonth')}
                      </span>
                    </Typography>
                  </Box>
                </CardContent>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1.5 }}> {/* Reducir padding */}
                  {/* IVA no incluido */}
                  <Typography variant="caption" sx={{ color: '#595959' }}>
                  {t('widgetPricing.vatNotIncluded') || '*IVA no incluido'}
                  </Typography>
                  {/* Botón Comprar */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSelectPlan(plan)}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '9px',
                      paddingX: 3,
                      paddingY:'10px',
                      fontSize: '16px',
                    }}
                  >
                    {t('widgetPricing.buyButton') || 'Comprar'}
                  </Button>
                </Box>
              </Card>
            </Grid>
          );
        })}

        {/* Tarjeta Personalizada */}
        <Grid item xs={12} sm={6} md={3} ml={0}>
          <Card
            sx={{
              borderTop: '4px solid #0F52BD',
              height: '330px', // Altura de las tarjetas
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderRadius: '10px',
              boxShadow: '0px 2px 25px 0px rgba(15, 82, 189, 0.46)',
              position: 'relative', // Necesario para usar transform
              marginLeft:'20px'
            }}
          >
            <CardContent sx={{ p: 0 }}>
              {/* Encabezado Azul */}
              <Box
                sx={{
                  backgroundColor: '#0F52BD', // Fondo azul
                  padding: '16px', // Espaciado interno
                  textAlign: 'center', // Centrar texto dentro del encabezado
                  width: '100%', // Asegurar que ocupe todo el ancho
                }}
              >
                <Typography variant="h5" sx={{ color: '#FFFFFF', mb: 0 }}>
                {t('widget.customPlan') || 'Custom'}
                </Typography>
              </Box>
              <Box sx={{ padding: '16px' }}>
                {/* Línea Divisoria Azul */}
                <Box sx={{ borderBottom: '1px solid #7894CC', mb: 1, mt:8 }} />

                <Typography variant="subtitle1" sx={{ mb: 1, display: 'flex', flexDirection: 'column' }}>
                  {/* Reducir espacio */}
                  <span style={{ fontWeight: 'bold', color: '#595959', fontSize: '18px' }}>
                    {t('widgetPricing.customVisits') || 'A partir de 3.000.000'}
                  </span>
                  <span style={{ color: '#666', fontSize: '14px' }}>
                    {t('widgetPricing.customVisitsDescription') || 'visitas mensuales'}
                  </span>
                </Typography>
              </Box>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
              {/* IVA no incluido */}
              <Typography variant="caption" sx={{ color: '#555' }}>
              {t('widgetPricing.vatNotIncluded') || 'IVA no incluido'}
              </Typography>
              {/* Botón Contacta */}
              <Button
                variant="outlined"
                href="https://goviewser.com/contacto/"
                target="_blank" // Abre el enlace en una nueva pestaña
                rel="noopener noreferrer" // Seguridad al usar target="_blank"
                sx={{
                  textTransform: 'none',
                  borderRadius: '7px',
                  paddingX: 3,
                  fontSize: '16px',
                  borderColor: '#0F52BD',
                  color: '#0F52BD',
                  '&:hover': {
                    backgroundColor: '#0F52BD',
                    color: '#fff',
                  },
                }}
              >
                {t('widgetPricing.contactButton') || 'Contacta'}
              </Button>
            </Box>
          </Card>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1, // Margen superior para separar de las tarjetas
            gap: 1, // Espaciado entre los textos
            mb:5
          }}
        >
          {/* Texto de la izquierda */}
          <Typography
            sx={{
              fontWeight: 'medium',
              color: '#595959',
              fontSize: '16px',
            }}
          >
            {t('widgetPricing.domainExceedsQuestion') || '¿Tu web sobrepasa las 3.000.000 de visitas mensuales?'}
          </Typography>

          {/* Enlace a la derecha */}
          <Typography
            component="a"
            href="https://goviewser.com/contacto/"
            target="_blank" // Abre el enlace en una nueva pestaña
            rel="noopener noreferrer" // Seguridad al usar target="_blank" // Cambia esto a la URL que corresponda
            sx={{
              textDecoration: 'none',
              color: '#0F52BD',
              fontWeight: 'medium',
              fontSize: '16px',
              '&:hover': {
                textDecoration: 'underline', // Subrayado al pasar el ratón
              },
            }}
          >
            {t('widgetPricing.contactTeam') || 'Contacta con nuestro equipo.'}
          </Typography>
        </Box>
        </Grid>
        {error && <Alert severity="error" sx={{ mt: 4 }}>{error}</Alert>}
    </Container>
  );  
};

// Función auxiliar para formatear el precio
const formatPrice = (amount) => {
  return (amount / 100).toFixed(0);
};

export default ModificarPlanAWS;
