// src/components/ChangePasswordModal.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';
import { supabase } from '../services/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const ChangePasswordModal = ({ open, handleClose }) => {
  const { user } = useAuth(); // Get user from context
  const { t } = useTranslation(); // Extract 't' for translations
  
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [hasPassword, setHasPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (user && user.identities) {
      // Check if user has an email provider identity
      const emailIdentity = user.identities.find(identity => identity.provider === 'email');
      setHasPassword(!!emailIdentity);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const { currentPassword, newPassword, confirmNewPassword } = formData;

    // Basic validations
    if (!newPassword || !confirmNewPassword) {
      setError(t('changePassword.completeAllFields'));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError(t('changePassword.passwordsDoNotMatch'));
      return;
    }

    if (newPassword.length < 6) {
      setError(t('changePassword.passwordTooShort'));
      return;
    }

    if (hasPassword && !currentPassword) {
      setError(t('changePassword.enterCurrentPassword'));
      return;
    }

    setLoading(true);

    try {
      if (hasPassword) {
        // Re-authenticate user with current password
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email: user.email,
          password: currentPassword,
        });

        if (signInError) {
          setError(t('changePassword.currentPasswordIncorrect'));
          setLoading(false);
          return;
        }
      }

      // Update the password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        setError(updateError.message || t('changePassword.updateError'));
        setLoading(false);
        return;
      }

      setSuccess(t('changePassword.updateSuccess'));
      setFormData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });

      // Optional: Close modal after a short period
      setTimeout(() => {
        handleClose();
        setSuccess('');
      }, 2000);
    } catch (err) {
      console.error('Error changing password:', err);
      setError(t('changePassword.unexpectedError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('changePassword.title')}</DialogTitle>
      <DialogContent>
        {/* Alerts */}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <Box component="form" onSubmit={handleSubmit}>
          {hasPassword && (
            <TextField
              label={t('changePassword.currentPassword')}
              name="currentPassword"
              type="password"
              value={formData.currentPassword}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          )}
          <TextField
            label={t('changePassword.newPassword')}
            name="newPassword"
            type="password"
            value={formData.newPassword}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('changePassword.confirmNewPassword')}
            name="confirmNewPassword"
            type="password"
            value={formData.confirmNewPassword}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          {t('changePassword.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          color="primary"
        >
          {loading ? <CircularProgress size={24} /> : t('changePassword.changePassword')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
