// src/pages/Dashboard/Home.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress, ButtonBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Corregí el ícono de búsqueda
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { user } = useAuth(); // Asegúrate de que 'user' contiene 'id'
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState('');
  const [widgetsCount, setWidgetsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); // Extraemos solo 't'

  useEffect(() => {
    const fetchCustomerName = async () => {
      try {
        const response = await api.get(`/customer-name/${user.id}`);
        setCustomerName(response.data.customerName);
      } catch (error) {
        console.error('Error al obtener el nombre del cliente:', error);
      }
    };

    const fetchWidgetsCount = async () => {
      try {
        const response = await api.get(`/widgets-count/${user.id}`);
        setWidgetsCount(response.data.widgetsCount);
      } catch (error) {
        console.error('Error al obtener el número de widgets contratados:', error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchCustomerName(), fetchWidgetsCount()]);
      setLoading(false);
    };

    fetchData();
  }, [user.id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      {/* Saludo al Usuario */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: '#000' }}>
        {t('home.greeting', { name: customerName })}
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '18px', color: '#4D4D4D' }}>
        {t('home.summary')}
      </Typography>

      {/* Cajas Informativas */}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {/* Caja de Widget */}
        <Grid item xs={12} sm={4}>
          <ButtonBase
            onClick={() => navigate('/dashboard/widget')}
            sx={{
              display: 'block', // Para que el botón ocupe todo el espacio
              textAlign: 'left', // Mantener texto alineado a la izquierda
              width: '100%',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 3,
                position: 'relative',
                height: '100%',
                boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* Parte Superior: Icono y Título */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SearchIcon sx={{ fontSize: 28, fontWeight: 'bold', mr: 1, color: '#000' }} />
                <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '18px', color: '#000' }}>
                  {t('home.widgetTitle')}
                </Typography>
              </Box>

              {/* Descripción */}
              <Typography variant="body1" gutterBottom sx={{ fontSize: '14px', color: '#4D4D4D', mb: 20 }}>
                {t('home.widgetDescription')}
              </Typography>

              {/* Información Adicional */}
              <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
                <Typography variant="caption" sx={{ fontSize: '14px', color: '#4D4D4D' }}>
                  {t('home.widgetsContracted')}
                </Typography>
                <Typography variant="caption" sx={{fontSize:'48px', color:'#4F4F4F'}}>
                  {widgetsCount}
                </Typography>
              </Box>
            </Paper>
          </ButtonBase>
        </Grid>

        {/* Caja de Próximamente */}
        <Grid item xs={12} sm={4}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              height: '100%',
              boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Parte Superior: Icono y Título */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <img
                src={require('../../assets/WidgetIcon.svg').default}
                alt={t('home.comingSoon')}
                style={{ width: '27px', height: '27px', marginRight: '8px' }}
              />
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '18px', color: '#000' }}>
                {t('home.comingSoon')}
              </Typography>
            </Box>

            {/* Descripción */}
            <Typography variant="body1" sx={{ fontSize: '14px', color: '#4D4D4D', mb: 20 }}>
              {t('home.comingSoonDescription')}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
